import React, {useState} from "react";

import api from "../utils/api";

import {useCookies} from "react-cookie";

import CloseIcon from "../images/close_btn.svg";

export default function FoodDiaryCard(props) {
	const [cookies] = useCookies(["token", "id"]);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	const amountClassName = `justify-self-center m-0 px-2.5 rounded font-semibold max-h-22
                            ${props.amount > 500 ? "bg-red text-white" : ""}
                            ${
		props.amount >= 450 && props.amount <= 500
			? "text-green"
			: ""
	}`;

	function handleDeleteBtn() {
		return api
			.deleteFoodDiary(token, {
				id: idUser,
				diaryId: props.diaryId,
				dailyFoodDiaryId: props.dailyFoodDiaryId,
			})
			.then((res) => {
				api
					.getFoodDiaryTable(token, {id: idUser, diaryId: props.diaryId})
					.then((res) => {
						props.setTableData(res?.foodDiaryTableData);
					});
			})
			.catch((err) => console.log(err));
	}

	function handleCardClick() {
		props.handleCardClick(props.time, props.product, props.gram, props.cal);
	}

	return (
		<>
			<div
				className={
					"grid grid-cols-8 px-2 md:px-6 gap-x-3 w-full text-xs md:text-sm items-center"
				}
			>
				<button
					onClick={handleCardClick}
					className={"grid grid-cols-6 col-span-7 cursor-pointer"}
				>
					<p className={"justify-self-center"}>{props.time}</p>
					<p
						className={"col-start-2 col-span-2 justify-self-center text-center"}
					>
						{props.product}
					</p>
					<p className={"justify-self-center"}>{props.gram}</p>
					<p className={"col-start-5 justify-self-center"}>{props.cal}</p>
					<p className={amountClassName}>{props.amount}</p>
				</button>
				<button
					onClick={handleDeleteBtn}
					className={"justify-self-end col-start-8"}
				>
					<img src={CloseIcon} alt={"close-icon"}/>
				</button>
			</div>
		</>
	);
}