import React, {useEffect, useRef, useState} from "react";

import api from "../utils/api";

import {find, replace} from "lodash";
import moment from "moment";

import ReactHtmlParser from "react-html-parser";
import {useCookies} from "react-cookie";

import {Input} from "@mantine/core";
import {Calendar, DatePicker} from "@mantine/dates";
import {useScrollIntoView} from "@mantine/hooks";

import Select from "./Select";
import ErrorModal from "./ErrorModal";
import WeightTableCard from "./WeightTableCard";

export default function WeightDiary(props) {
	const [cookies] = useCookies(["token", "id"]);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	const [phases, setPhases] = useState([]);
	const [calendarDate, setCalendarDate] = useState(moment()._d);
	const [changeTableData, setChangeTableData] = useState([]);
	const [value, setValue] = useState("");
	const [weight, setWeight] = useState("");

	const [errors, setErrors] = useState([]);
	const [errorTitle, setErrorTitle] = useState("");
	const [errorMessageTitle, setErrorMessageTitle] = useState("");
	const [errorMessageText, setErrorMessageText] = useState("");
	const [errorMessageStatus, setErrorMessageStatus] = useState("");

	const [opened, setOpened] = useState(false);

	const {
		scrollIntoView: desktopScrollIntoView,
		targetRef: desktopScroll,
		scrollableRef: desktopScrollableRef
	} = useScrollIntoView();

	const mobileScrollRef = useRef(null)

	function resolveCommentBlockClassName() {
		if (errorMessageTitle === "") {
			return "hidden";
		}

		let colorClass = "";
		switch (errorMessageStatus) {
			case "ERROR":
				colorClass = "bg-red";
				break;
			case "WARNING":
				colorClass = "bg-yellow";
				break;
			case "INFO":
				colorClass = "bg-green";
				break;
			default:
				colorClass = "";
				break;
		}

		return "absolute left-0 top-0 h-full w-5 rounded-l-xl " + colorClass;
	}

	useEffect(() => {
		Promise.all([
			api.getPhases(idUser, token),
			api.getWeightDiaryTable(token, {id: idUser}),
		]).then((res) => {
			const phaseNames = res[0].map((item) => {
				return {value: item.id + "", label: item.phaseName};
			});

			setPhases(phaseNames);

			const lastIndexOfTableData = res[1].changeTableData.length - 1;
			const lastItemOfTableData = res[1].changeTableData[lastIndexOfTableData];

			if (lastItemOfTableData) {
				const findPhaseId = find(
					phaseNames,
					(item) => item.label === lastItemOfTableData.diaryDayPhaseCaption
				)?.value;
				setValue(findPhaseId);
				setWeight(lastItemOfTableData.morningWeight);
				setCalendarDate(
					moment(lastItemOfTableData.diaryDateFormated, "DD/MM").toDate()
				);
			}

			setChangeTableData(res[1].changeTableData);
		});
	}, [idUser, token]);

	function checkSaveWeightDiaryInput() {
		if (weight === "" || value === "") {
			return {
				isValid: false,
				title: "Hoiatus",
				errors: ["Palun sissestage kõik väärtused"],
			};
		}

		if (!/^\d+[.]?\d$/.test(weight)) {
			return {
				isValid: false,
				title: " ",
				errors: ["Kaal peab olema formaadis: xx.x"],
			};
		}

		return {isValid: true};
	}

	function handleSaveWeightDiary() {
		setErrors("");
		setErrorMessageTitle("");
		setErrorMessageText("");

		const validationRes = checkSaveWeightDiaryInput();
		if (!validationRes.isValid) {
			setErrors([validationRes.errors]);
			setErrorTitle(validationRes.title);
			setOpened(true);
			return;
		}

		api
			.addWeightDiary(token, {
				phaseId: value,
				weight: parseFloat(weight),
				date: moment(calendarDate).format("YYYY-MM-D"),
				id: idUser,
			})
			.then((res) => {
				api.getWeightDiaryTable(token, {id: idUser}).then((res) => {
					setChangeTableData(res.changeTableData);
				});
				return res;
			})
			.then((res) => {
				const lastDate = moment(calendarDate).format("DD/MM");
				const lastAddedCard = find(res.changeTableData, (o) => {
					return o.diaryDateFormated === lastDate;
				});

				let messageText = lastAddedCard?.weightChangeNotificationArea?.text;

				if (lastAddedCard.weightChangeNotificationArea) {
					lastAddedCard.weightChangeNotificationArea.map.map((item, index) => {
						let itemLink = item.link;
						let itemText = item.text;

						if (item.text === "Isiklik Pöördumine") {
							itemLink = "/app/feedback";
						} else {
							itemLink = item.link;
						}

						const params = new URLSearchParams({
							pdf: itemLink,
							text: itemText,
						}).toString();

						return (messageText = replace(
							messageText,
							item.link,
							index === 0
								? `<a class="text-green" target="_blank" href=/app/guide/?${params}>${itemText}</a>`
								: `<a target="_blank" class=" text-green" href=${itemLink}>${itemText}</a>`
						));
					});
					setErrorMessageTitle(
						lastAddedCard.weightChangeNotificationArea.title
					);
				}

				setErrorMessageText(ReactHtmlParser(messageText));
				setErrorMessageStatus(lastAddedCard.weightChangeStatus);

				setWeight("");
			})
			.catch((err) => {
				// console.log(err)
				setErrors(err.split(","));
				setErrorTitle("Palun kinnitage");
				setOpened(true);
				console.log(err);
			})
			.finally(() => {
				if (typeof window !== 'undefined') {
					setTimeout(function () {
						mobileScrollRef.current.scrollIntoView({behavior: 'smooth'});

						desktopScrollIntoView();
					}, 300);
				}
			});
	}

	function handleCardClick(date, step, cal) {
		const findClickedPhasesId =
			find(phases, (item) => item.label === step)?.value + "";
		setValue(findClickedPhasesId);
		setWeight(cal);
		setCalendarDate(moment(date, "DD/MM").toDate());
	}

	function handleDeleteBtn(id) {
		setErrorMessageTitle("");
		setErrorMessageText("");
		return api
			.deleteWeightDiary(token, {diaryDayId: id, id: idUser})
			.then((res) => {
				api.getWeightDiaryTable(token, {id: idUser}).then((res) => {
					setChangeTableData(res.changeTableData);
				});
			});
	}

	useEffect(() => {
		const lastIndexOfTableData = changeTableData.length - 1;
		const lastItemOfTableData = changeTableData[lastIndexOfTableData];

		if (lastItemOfTableData) {
			const findPhaseId = find(
				phases,
				(item) => item.label === lastItemOfTableData.diaryDayPhaseCaption
			)?.value;
			setValue(findPhaseId);
			setWeight(lastItemOfTableData.morningWeight);
			setCalendarDate(
				moment(lastItemOfTableData.diaryDateFormated, "DD/MM").toDate()
			);
		}
	}, [changeTableData, phases]);

	return (
		<>
			<div className={"max-h-full md:max-h-650"}>
				<div className={"flex flex-col md:flex-row"}>
					<div className={"w-full md:w-5/12"}>
						<div className={"h-45px"}>
							<Select
								value={value}
								setValue={setValue}
								selectedDietDay={true}
								data={phases}
								styles={{dropdown: {overflow: "hidden"}}}
							/>
						</div>

						<div className={"flex justify-between h-12 md:h-11"}>
							<DatePicker
								value={calendarDate}
								onChange={setCalendarDate}
								className={"block md:hidden h-full"}
								placeholder="Dieedi päev"
								radius="xl"
								styles={{
									rightSection: {margin: "0"},
									icon: {justifySelf: "end"},
									wrapper: {height: "54px"},
									input: {height: "100%"},
								}}
								minDate={moment().startOf("year").toDate()}
								maxDate={moment().endOf("year").toDate()}
							/>

							<Input
								className={"ml-3 md:ml-0"}
								value={weight}
								onChange={(event) => {
									setWeight(event.target.value);
								}}
								type="text"
								placeholder="Kaal (XX.X)"
								radius={"xl"}
								styles={{
									wrapper: {height: "100%", margin: "0", position: "static"},
									input: {height: "100%"},
								}}
							/>

							<button
								onClick={handleSaveWeightDiary}
								className={
									"hidden md:block bg-green text-sm px-7 ml-3 rounded-3xl text-white"
								}
							>
								Sisesta
							</button>
						</div>
						<div
							className={
								"hidden md:flex w-full justify-center mt-5 pb-2.5 rounded-xl sm:shadow-md hidden sm:flex"
							}
						>
							<Calendar
								value={calendarDate}
								onChange={setCalendarDate}
								month={calendarDate}
								onMonthChange={setCalendarDate}
								minDate={moment().startOf("year").toDate()}
								maxDate={moment().endOf("year").toDate()}
							/>
						</div>
						<button
							onClick={handleSaveWeightDiary}
							className={
								"block md:hidden bg-green text-sm px-7 w-full py-2 mt-5 rounded-3xl text-white"
							}
						>
							Sisestada
						</button>
					</div>
					<div
						className={
							"w-full rounded-xl shadow py-2 w-8/12 ml-0 md:ml-6 mt-5 md:mt-0"
						}
					>
						<div className={"overflow-y-auto h-460"} ref={desktopScrollableRef}>
							<h2 className={"text-base text-center"}>Kaalumuutuse tabel</h2>
							<div className={"flex px-2 md:px-6"}>
								<div
									className={
										"w-full grid grid-cols-7 gap-x-3 text-xs text-gray"
									}
								>
									<h4 className={"md:justify-self-center"}>Kuupäev</h4>
									<h4 className={"justify-self-center col-start-2 col-span-2"}>
										Samm
									</h4>
									<h4 className={"justify-self-center"}>Päev</h4>
									<h4 className={"justify-self-center col-start-5 col-span-2"}>
										Kaal
									</h4>
									<h4 className={"justify-self-center"}>Muutus</h4>
								</div>
								<div className={"w-4 ml-6 md:ml-12"}></div>
							</div>

							{changeTableData.map((item) => (
								<WeightTableCard
									key={item.diaryId}
									id={item.diaryId}
									handleCardClick={handleCardClick}
									handleDeleteBtn={handleDeleteBtn}
									errorMessageStatus={errorMessageStatus}
									status={item.weightChangeStatus}
									date={item.diaryDateFormated}
									step={item.diaryDayPhaseCaption}
									day={item.dietDay}
									cal={item.morningWeight}
									changes={item.weightChanged}
								/>
							))}

							<div ref={desktopScroll}/>
						</div>
					</div>
				</div>

				<div
					className={
						"relative shadow h-full min-h-150 w-full rounded-xl mt-5 px-4 py-2"
					}
				>
					{changeTableData.length >= 1 && changeTableData != null && (
						<div>
							<div className={resolveCommentBlockClassName()}></div>
							<div className={"ml-6 mt-0"}>
								<p className={"text-lg font-semibold text-primary"}>
									{errorMessageTitle}
								</p>
								<p className={"mt-3.5 text-xs md:text-sm"}>
									{errorMessageText}
								</p>
							</div>
						</div>
					)}
					<div ref={mobileScrollRef}/>
				</div>

				<ErrorModal
					title={errorTitle}
					opened={opened}
					setOpened={setOpened}
					errors={errors}
				/>
			</div>
		</>
	);
}