import React, {useEffect, useState} from "react";

import api from "../utils/api";

import {find} from "lodash";

import {Input, Textarea} from "@mantine/core";

import {useCookies} from "react-cookie";

import ErrorModal from "./ErrorModal";
import FoodDiaryCard from "./FoodDiaryCard";
import Select from "./Select";

export default function FoodDiary(props) {
	const [cookies] = useCookies(["token", "id"]);
	const [token] = useState(cookies["token"]);
	const [idUser] = useState(cookies["id"]);

	const [allowedFoods, setAllowedFoods] = useState([]);
	const [caloriesPer100g, setCaloriesPer100g] = useState("");

	const [selectValue, setSelectValue] = useState("");
	const [gramValue, setGramValue] = useState("");
	const [caloriesValue, setCaloriesValue] = useState("");
	const [timeValue, setTimeValue] = useState("");
	const [tableData, setTableData] = useState(null);
	const [comment, setComment] = useState("");
	const [showMessage, setShowMessage] = useState(false);
	const [saveCommentMessage, setSaveCommentMessage] = useState("");
	const [countCalories, setCountCalories] = useState(false);

	const [openModal, setOpenModal] = useState(false);
	const [errors, setErrors] = useState([]);

	const [selectedDayCaptionId, setSelectedDayCaptionId] = useState(null);
	const [dayCaptions, setDayCaptions] = useState([]);

	useEffect(() => {
		api
			.getDietDays(token, {id: idUser})
			.then((res) => {
				const dayCaptions = res.map((item) => ({
					value: item.id + "",
					label: item.diaryDayCaption,
				}));
				setDayCaptions(dayCaptions);
			})
			.catch((err) => console.log(err));
	}, [idUser, token]);

	useEffect(() => {
		api.getAllowedFoods(token, {id: idUser}).then((res) => {
			setAllowedFoods(res);
		});
	}, [idUser, token]);

	useEffect(() => {
		if (selectedDayCaptionId) {
			api
				.getFoodDiaryTable(token, {id: idUser, diaryId: selectedDayCaptionId})
				.then((res) => {
					setTableData(res.foodDiaryTableData);
				})
				.catch((err) => console.log(err));

			api
				.getDietDays(token, {id: idUser})
				.then((res) => {
					const selectedDay = find(res, (o) => {
						// console.log(o.id)
						return o.id + "" === selectedDayCaptionId;
					});

					if (!selectedDay) {
						setComment("");
						setShowMessage(false);
						return;
					}

					setComment(selectedDay.notes ?? "");

					const shouldShowMessage =
						selectedDay.phase === 2 || selectedDay.phase === 3;
					setShowMessage(!shouldShowMessage);
				})
				.catch((err) => console.log(err));
		}
	}, [selectedDayCaptionId, idUser, token]);

	useEffect(() => {
		setSaveCommentMessage("");
		setShowMessage(false);
		setTableData(null);
		setSelectValue("");
		setCaloriesPer100g("");
		setCaloriesValue("");
		setGramValue("");
		setComment("");
		setTimeValue("");
	}, [selectedDayCaptionId, idUser, token]);

	function handleAddNoteClick(event) {
		event.preventDefault();
		api
			.addFoodDiaryNote(token, {
				diaryId: selectedDayCaptionId,
				note: comment,
				id: idUser,
			})
			.then((res) => setSaveCommentMessage("Märkmed on salvestatud"))
			.catch((err) => console.log(err));
	}

	useEffect(() => {
		if (!selectValue) {
			return;
		}
		setCaloriesPer100g(
			find(allowedFoods, (item) => item.name === selectValue)?.caloriesPer100g
		);
	}, [selectValue, allowedFoods]);

	useEffect(() => {
		if (countCalories)
			setCaloriesValue(
				parseFloat((gramValue / 100) * caloriesPer100g).toFixed(0)
			);
	}, [gramValue, caloriesPer100g, countCalories]);

	function checkSaveFoodDiaryInput() {
		if (selectValue !== "" && (gramValue === "" || caloriesValue === "")) {
			return {
				isValid: false,
				errors: [
					"Vale kalorite sisestus, peab olema rohkem, kui 0 ja vähem kui 500",
				],
			};
		}
		if (selectValue === "" || gramValue === "" || caloriesValue === "") {
			return {isValid: false, errors: ["Lubatud toiduaine pole valitud"]};
		}

		return {isValid: true};
	}

	function handleAddFoodDiary(event) {
		event.preventDefault();
		setErrors([]);

		const validationRes = checkSaveFoodDiaryInput();
		if (!validationRes.isValid) {
			setErrors([validationRes.errors]);
			setOpenModal(true);
			return;
		}

		let customTimeValue = " ";

		if (timeValue === "") {
			customTimeValue = " ";
		} else {
			customTimeValue = timeValue;
		}

		api
			.addFoodDiary(token, {
				id: idUser,
				diaryId: selectedDayCaptionId,
				timeTaken: customTimeValue,
				productName: selectValue,
				gram: parseFloat(gramValue),
				calories: parseFloat(caloriesValue),
			})
			.then((res) => {
				if (selectedDayCaptionId) {
					api
						.getFoodDiaryTable(token, {
							id: idUser,
							diaryId: selectedDayCaptionId,
						})
						.then((res) => {
							setTableData(res.foodDiaryTableData);
						})
						.catch((err) => console.log(err));
				}
				setCaloriesPer100g("");
				setCaloriesValue("");
				setSelectValue("");
				setGramValue("");
				setTimeValue("");
			})
			.catch((err) => console.log(err));
	}

	function handleCardClick(time, product, gram, calories) {
		setCountCalories(false);
		setSelectValue(product);
		setTimeValue(time);
		setCaloriesValue(calories);
		setGramValue(gram);
	}

	return (
		<>
			<div className={"h-full md:h-650 flex flex-col md:flex-row"}>
				<form
					className={"md:w-6/12 lg:w-3/12 flex flex-col mt-12"}
					onSubmit={handleAddFoodDiary}
				>
					<p className={"text-xs ml-3"}>Dieedi päev:</p>
					<div className={"h-11 mt-2"}>
						<Select
							data={dayCaptions}
							value={selectedDayCaptionId}
							setValue={setSelectedDayCaptionId}
							selectedDietDay={true}
						/>
					</div>

					<div className={"flex mt-3"}>
						<div className={"w-full"}>
							<p className={"text-xs ml-3"}>Lubatud toiduaine</p>
							<div className={"mt-2"}>
								<Select
									data={allowedFoods.map((item) => {
										return {value: item.name, label: item.name};
									})}
									selectedDietDay={selectedDayCaptionId}
									value={selectValue}
									setValue={setSelectValue}
									searchable
									textLeft
									styles={{
										dropdown: {overflow: "hidden"},
										root: {height: "45px"},
									}}
								/>
							</div>
						</div>

						{/*Mobile version*/}
						<div className={"block md:hidden w-6/12 h-full ml-3 mt-0"}>
							<p className={"text-xs ml-3"}>Aeg</p>
							<Input
								className={"mt-2 h-10"}
								radius={"xl"}
								disabled={!selectedDayCaptionId}
								value={timeValue}
								onChange={(event) => setTimeValue(event.target.value)}
								styles={{
									input: {height: "100%"},
									wrapper: {height: "100%", marginTop: "9px"},
									root: {height: "45px"},
								}}
							/>
						</div>
					</div>

					<div className={"flex w-full justify-between mt-4 md:mt-3"}>
						<div className={"max-h-full"}>
							<p className={"text-xs ml-3"}>Grammid</p>
							<Input
								radius={"xl"}
								placeholder={"Grammid"}
								disabled={!selectValue}
								value={gramValue}
								onChange={(event) => {
									setCountCalories(true);
									setGramValue(event.currentTarget.value);
								}}
								className={"h-11"}
								styles={{
									wrapper: {marginTop: "9px", height: "100%"},
									input: {height: "100%"},
								}}
							/>
						</div>
						<div className={"max-h-full mt-0 ml-2"}>
							<p className={"text-xs ml-3"}>Kalorid</p>
							<Input
								radius={"xl"}
								placeholder={"Kalorid"}
								disabled={!selectValue}
								value={caloriesValue}
								onChange={(event) => setCaloriesValue(event.target.value)}
								className={"h-11"}
								styles={{
									wrapper: {marginTop: "9px", height: "100%"},
									input: {height: "100%"},
								}}
							/>
						</div>
					</div>
					<p className={"hidden md:block text-xs ml-3 mt-3"}>Aeg</p>
					<div className={"flex w-full justify-between h-11 mt-5 md:mt-2"}>
						<Input
							className={"hidden md:block w-6/12 h-full"}
							radius={"xl"}
							disabled={!selectedDayCaptionId}
							value={timeValue}
							onChange={(event) => setTimeValue(event.target.value)}
							styles={{input: {height: "100%"}}}
						/>
						<button
							type={"submit"}
							disabled={!selectedDayCaptionId}
							className={
								"disabled:bg-gray bg-green rounded-3xl text-white text-sm w-full md:w-6/12 md:ml-3 py-1.5 md:py-0"
							}
						>
							Sisesta
						</button>
					</div>
					{showMessage ? (
						<p className={"text-center mt-4 text-sm"}>
							Toitumise päevikut võib pidada ainult Dieedi ja Mahalaadimise
							Sammu ajal
						</p>
					) : (
						""
					)}
				</form>
				<div
					className={
						"flex flex-col w-full md:6/12 lg:w-9/12 ml-0 mt-5 md:ml-6 md:mt-0 h-full"
					}
				>
					<div
						className={"w-full rounded-xl shadow py-2 overflow-y-auto h-460"}
					>
						<div
							className={
								"w-full grid grid-cols-8 px-2 md:px-6 gap-x-3 text-xs text-gray"
							}
						>
							<div className={"grid grid-cols-6 col-span-7"}>
								<h4 className={"justify-self-center"}>Aeg</h4>
								<h4 className={"justify-self-center col-start-2 col-span-2"}>
									Toiduaine
								</h4>
								<h4 className={"justify-self-center"}>Grammid</h4>
								<h4 className={"justify-self-center col-start-5"}>Kalorid</h4>
								<h4 className={"justify-self-center"}>Summa</h4>
							</div>
							<div></div>
						</div>
						{tableData?.map((item) => (
							<FoodDiaryCard
								key={item.id}
								diaryId={selectedDayCaptionId}
								dailyFoodDiaryId={item.id}
								setTableData={setTableData}
								handleCardClick={handleCardClick}
								time={item.timeTaken}
								product={item.productName}
								gram={item.gram}
								cal={item.calories}
								amount={item.caloriesSum}
							/>
						))}
					</div>
					{saveCommentMessage ? (
						<p className={"mt-3 text-sm text-green ml-3"}>
							{saveCommentMessage}
						</p>
					) : (
						""
					)}
					<form
						onSubmit={handleAddNoteClick}
						className={"hidden md:flex flex-col justify-between"}
					>
						<Textarea
							placeholder="Märkused"
							radius="lg"
							value={comment}
							onChange={(event) => {
								setComment(event.currentTarget.value);
								setSaveCommentMessage("");
							}}
							className={"hidden md:block h-24"}
							styles={{
								wrapper: {height: "100%"},
								input: {height: "100%"},
							}}
						/>
						<button
							type={"submit"}
							className={
								"hidden md:block bg-green rounded-3xl text-white text-sm mt-5 py-3 w-32"
							}
						>
							Säilitada
						</button>
					</form>
				</div>

				{/*Mobile version*/}
				<form onSubmit={handleAddNoteClick}>
					<Textarea
						placeholder="Märkused"
						radius="lg"
						value={comment}
						onChange={(event) => {
							setComment(event.currentTarget.value);
							setSaveCommentMessage("");
						}}
						className={"block md:hidden h-28"}
						styles={{wrapper: {height: "100%"}, input: {height: "100%"}}}
					/>
					<button
						type={"submit"}
						className={
							"w-full block md:hidden bg-green rounded-3xl text-white text-sm mt-5 py-2 "
						}
					>
						Säilitada
					</button>
				</form>
				<ErrorModal
					title={" "}
					opened={openModal}
					setOpened={setOpenModal}
					errors={errors}
				/>
			</div>
		</>
	);
}
