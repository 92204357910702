import React, {useState} from "react";

import {Tabs} from "@mantine/core";

import Section from "../../components/Section";
import Layout from "../../components/Layout";
import WeightDiary from "../../components/WeightDiary";
import WeightGraphDiary from "../../components/WeightGraphDiary";
import FoodDiary from "../../components/FoodDiary";

export default function Diary(props) {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<>
			<Layout location={props.location}>
				<Section title={" "}>
					<Tabs
						active={activeTab}
						onTabChange={setActiveTab}
						className={"w-full"}
						styles={{
							tabControl: {height: "60px", width: "33%"},
							tabsList: {justifyContent: "space-between"},
							root: {margin: 0},
						}}
					>
						<Tabs.Tab label="Kaalupäevik">
							<WeightDiary/>
						</Tabs.Tab>
						<Tabs.Tab label="Kaalugraafik">
							<WeightGraphDiary/>
						</Tabs.Tab>
						<Tabs.Tab label="Toitumispäevik">
							<FoodDiary/>
						</Tabs.Tab>
					</Tabs>
				</Section>
			</Layout>
		</>
	);
}
