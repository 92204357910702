import React from "react";

import {Modal} from "@mantine/core";

const ErrorModal = (props) => {
	return (
		<Modal
			title={props.title}
			opened={props.opened}
			centered={true}
			radius="xl"
			styles={{
				title: {margin: "auto", fontWeight: "bold", fontSize: "16px"},
				body: {fontWeight: "lighter"},
			}}
			onClose={() => props.setOpened(false)}
		>
			<div className={"flex flex-col"}>
				{props.errors
					? props.errors.map((error, index) => (
						<p key={index} className={"text-center font-normal"}>
							{error}
						</p>
					))
					: ""}
				<button
					onClick={() => props.setOpened(false)}
					className={
						"bg-green text-white rounded-3xl px-14 py-2 mt-5 self-center"
					}
				>
					Ok
				</button>
			</div>
		</Modal>
	);
};

export default ErrorModal;