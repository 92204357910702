import React, {useRef} from "react";

import {Select} from "@mantine/core";

import SelectArrow from "../images/selectArrow.svg";

export default function FoodDiary(props) {
	const ref = useRef();

	return (
		<div className={"relative"}>
			<Select
				disabled={!props.selectedDietDay}
				value={props.value}
				onChange={props.setValue}
				ref={ref}
				data={props.data}
				radius="xl"
				clearable="true"
				transition="pop-top-left"
				searchable={props.searchable}
				styles={{
					input: {
						background: "#7AC142",
						fontSize: "16px",
						color: "white",
						textAlign: `${props.textLeft ? "left" : "center"}`,
						fontWeight: "600",
						height: "45px",
					},
					rightSection: {margin: 0, display: "none"},
					item: {
						padding: "4px",
						marginTop: "4px",
					},
					root: {
						overflowY: "hidden",
						overflow: "hidden",
					},
					dropdown: {
						paddingBottom: "20px",
						overflowY: "hidden",
						overflow: "hidden",
					},
				}}
			/>
			<button
				onKeyDown={() => ref?.current?.click()}
				onClick={() => ref?.current?.click()}
				type={"button"}
				className={"absolute top-4 right-4 cursor-pointer"}
			>
				<img src={SelectArrow} alt={"arrow-icon"}/>
			</button>
		</div>
	);
}