import React from "react";

import CloseIcon from "../images/close_btn.svg";

export default function WeightTableCard(props) {
	const changesClassName = `justify-self-center m-0 px-2.5 rounded py-1 font-semibold w-14
        ${props.status === "WARNING" ? "bg-yellow" : ""} 
        ${props.status === "ERROR" ? "bg-red text-white" : ""}`;

	function handleDeleteBtn() {
		props.handleDeleteBtn(props.id);
	}

	function handleCardClick() {
		props.handleCardClick(props.date, props.step, props.cal);
	}

	return (<>
		<div className={"flex px-2 md:px-6 w-full text-xs md:text-sm"}>
			<button
				onClick={handleCardClick}
				className={"gap-x-3 grid grid-cols-7 w-full items-center"}
			>
				<p className={"justify-self-center"}>{props.date}</p>
				<p
					className={"col-start-2 col-span-2 justify-self-center text-center break-all"}
				>
					{props.step}
				</p>
				<p className={"justify-self-center"}>{props.day}</p>
				<p
					className={"col-start-5 col-span-2 justify-self-center font-semibold"}
				>
					{props.cal}
				</p>
				<div className={changesClassName}>
					{props.changes}
				</div>
			</button>
			<button
				onClick={handleDeleteBtn}
				className={"justify-self-end col-start-8 cursor-pointer ml-6 md:ml-12"}
			>
				<img src={CloseIcon} alt={"close-icon"}/>
			</button>
		</div>
	</>);
}